import { SupportedLocale } from '@/models/Language';
import { PlanType, PlanTypeCard, planTypeDescriptions } from '@/models/PlanType';
import { getBillingOccurence, getMaxComparisonWithCategory, getRecurringFeeInCents } from '@/models/PlanVariant';
import { Product } from '@/models/Product';
import { getInstrumentsForLanguage, getInstrumentsForPlanCategory } from '@/utilities/Instrument';
import { LanguageCode } from 'iso-639-1';
import { MaybeRef, computed, unref } from 'vue';

export type UsePlanTypeCardsOptions = {
	products: MaybeRef<Product[]>;
	isTrial?: MaybeRef<boolean>;
	languageCode: MaybeRef<LanguageCode>;
}

export const usePlanTypeCards = (options: UsePlanTypeCardsOptions) => {
	const productsGroupedByType = computed(() => unref(options.products).reduce((obj, product) => {
		const { planType } = product.planVariant.CustomFields;
		return {
			...obj,
			[planType]: [
				...obj[planType] || [],
				product,
			],
		};
	}, {} as Partial<Record<PlanType, Product[]>>));

	const allPlanVariants = computed(() => unref(options.products).map(({ planVariant }) => planVariant));

	const instrumentsForLanguage = computed(() => getInstrumentsForLanguage(unref(options.languageCode) as SupportedLocale).map((i) => i.id));

	const planTypeCards = computed<PlanTypeCard[]>(() => Object.entries(productsGroupedByType.value).map<PlanTypeCard>(([planType, products]) => {
		const firstProduct = products[0];
		const firstPlanVariant = firstProduct.planVariant;
		const planTypeDescription = planTypeDescriptions[planType as PlanType];
		const billingOccurence = getBillingOccurence(firstPlanVariant);
		const pricePerBillingOccurenceInCents = getRecurringFeeInCents(firstPlanVariant);
		const plusTypeComparison = getMaxComparisonWithCategory(allPlanVariants.value, 'plus');
		const comparisonDiscountInPercent = planType as PlanType === 'plus' && plusTypeComparison.savingsInPercent > 0
			? plusTypeComparison.savingsInPercent
			: null;

		const instruments = products
			.flatMap((product) => getInstrumentsForPlanCategory(product.planVariant.CustomFields.category))
			.filter((instrument) => instrumentsForLanguage.value.includes(instrument));

		return {
			...planTypeDescription,
			billingOccurence,
			comparisonDiscountInPercent,
			discountInPercent: firstProduct.discount?.Effect.ReductionPercent ?? null,
			instruments,
			isTrial: unref(options.isTrial) ?? false,
			pricePerBillingOccurenceInCents,
			type: planType as PlanType,
		};
	}));

	return {
		planTypeCards,
	};
};
