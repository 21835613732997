<template>
	<div>
		<h2
			class="text-center text-[24px] mb-4 mx-auto tablet:w-8/12 desktop:w-10/12 widescreen:w-8/12 font-bold"
			data-cy="step_title"
		>
			{{ $t('subscribe.step.orderOverview.title') }}
		</h2>
		<p
			class="text-center mb-8 text-gray-700 leading-normal mx-auto phablet:w-8/12 desktop:w-9/12 widescreen:w-8/12"
			data-cy="step_subtitle"
		>
			{{ $t('subscribe.step.orderOverview.subtitle') }}
		</p>
		<div class="w-full mb-8 mx-auto phablet:w-8/12 desktop:w-10/12 widescreen:w-8/12">
			<CheckoutSummary v-if="selectedPlanVariant" />
			<div class="mb-8">
				<InlineNotification
					v-if="selectedPlanVariant"
				>
					<!-- eslint-disable vue/no-v-html -->
					<p v-html="orderOverviewNote" />
					<!-- eslint-enable -->
				</InlineNotification>
			</div>
			<div class="space-y-4">
				<AppCheckbox
					:model-value="useDiscountCode"
					data-cy="checkbox_useDiscountCode"
					@update:model-value="onDiscountCodeCheckboxChange"
				>
					<template #default>
						{{ $t('subscribe.step.orderOverview.checkboxes.discountCode') }}
					</template>
					<template #checked>
						<InputLookup
							id="discountCode"
							v-model="discount.code"
							:success="discount.isValid"
							:loading="discount.waitingForValidation"
							:input-label="$t('input.yourDiscountCode')"
							:button-label="$t('button.redeem')"
							icon="percentage"
							@submit="onDiscountCodeValidationSubmit"
						/>
					</template>
				</AppCheckbox>
			</div>
			<i18n-t
				keypath="view.signup.legalNotes.checkout.text"
				tag="p"
				scope="global"
				class="text-xs my-6 text-gray-700"
			>
				<template #actionTos>
					<a
						:href="staticLinkComposer.compose('termsOfUse')"
						class="text-primary-400 no-underline hover:cursor-pointer hover:underline"
						target="_blank"
						rel="noopener noreferrer"
					>
						{{ $t('view.signup.legalNotes.actionTOS') }}
					</a>
				</template>
				<template #actionCancellationPolicy>
					<a
						:href="staticLinkComposer.compose('dataPrivacy')"
						class="text-primary-400 no-underline hover:cursor-pointer hover:underline"
						target="_blank"
						rel="noopener noreferrer"
					>
						{{ $t('view.signup.legalNotes.actionCancellationPolicy') }}
					</a>
				</template>
			</i18n-t>
			<div class="mt-8 text-center relative flex justify-center">
				<AppButton
					:is-loading="waitingForSubscriptionCreation"
					:loading-message="$t('button.loadingMessage.waitingForSubscriptionCreation')"
					data-cy="button_confirmSignup"
					icon="lock-alt"
					@click="confirmSignup"
				>
					<FontAwesomeIcon
						icon="lock-alt"
						class="mr-2"
					/>
					{{ confirmButtonText }}
				</AppButton>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { DateTime } from 'luxon';
import errorHandler from '@/utilities/ErrorHandler';
import CheckoutSummary from '@/components/CheckoutSummary.vue';
import InputLookup from '@/components/InputLookup.vue';
import { getBillingOccurenceDescription } from '@/models/PlanVariant';
import { getFormattedPrice } from '@/models/Currency';
import { usePlanVariant } from '@/composables/usePlanVariant';
import { getEndDate } from '@/models/Discounts';
import { getDiscountedPriceInCents } from '@/models/Product';
import { useStaticLinkComposer } from '@/utilities/StaticLinks';
import { useRouter } from 'vue-router';
import { useCartStore } from '@/store/cart';
import { useI18n } from 'vue-i18n';
import { lookupDiscountCodeForOrder } from '@/api/discount';
import { storeToRefs } from 'pinia';
import { useMainStore } from '@/store/main';
import { useBillwerkStore } from '@/store/billwerk';
import { useProductsStore } from '@/store/products';
import { AppCheckbox, FontAwesomeIcon, InlineNotification } from '@sunlabde/m2m-library';
import { useLocalization } from '@/models/Localization';

const DISCOUNT_DEFAULT = {
	code: '',
	isValid: false,
	waitingForValidation: false,
	error: '',
};

const router = useRouter();
const staticLinkComposer = useStaticLinkComposer();

const confirmSignup = () => {
	router.push({ name: 'initiatePayment' });
};

const discount = ref({ ...DISCOUNT_DEFAULT });
const useDiscountCode = ref(false);
const cartStore = useCartStore();
const hasDiscountApplied = computed(() => cartStore.hasDiscountApplied);

const refreshDiscountCode = () => {
	if (hasDiscountApplied.value && cartStore.cart?.CouponCode) {
		useDiscountCode.value = true;
		discount.value.code = cartStore.cart.CouponCode;
		discount.value.isValid = true;
	} else {
		useDiscountCode.value = false;
		discount.value = { ...DISCOUNT_DEFAULT };
	}
};

const appliedDiscount = computed(() => cartStore.appliedDiscount);

const { t } = useI18n();

const productsStore = useProductsStore();
const { allowsTrialOrder } = storeToRefs(productsStore);

const { endOfTrialDate } = storeToRefs(useMainStore());

const billwerkStore = useBillwerkStore();
const { internalOrder } = storeToRefs(billwerkStore);

const onDiscountCodeValidationSubmit = async () => {
	if (!internalOrder.value) {
		return;
	}

	discount.value.waitingForValidation = true;
	const resolvedDiscount = await lookupDiscountCodeForOrder(discount.value.code, internalOrder.value);

	if (resolvedDiscount.isErr()) {
		errorHandler.report(resolvedDiscount.error);
		discount.value.isValid = false;
		discount.value.error = t('input.error.invalidCouponCode');
		discount.value.waitingForValidation = false;
		return;
	}

	const _endOfTrialDate = allowsTrialOrder.value ? endOfTrialDate.value : undefined;

	cartStore.addDiscount({ ...resolvedDiscount.value, code: discount.value.code }, _endOfTrialDate);
	discount.value.isValid = true;
	discount.value.waitingForValidation = false;
};

const onDiscountCodeCheckboxChange = () => {
	if (useDiscountCode.value) {
		cartStore.removeAllDiscounts();
		refreshDiscountCode();
		return;
	}

	useDiscountCode.value = true;
};

const selectedPlanVariant = computed(() => cartStore.cart?.planVariant);

const waitingForSubscriptionCreation = ref(false);

const confirmButtonText = computed(() => {
	if (allowsTrialOrder.value) {
		return t('button.confirmOrder_trial');
	}

	return t('button.confirmOrder');
});

const { localizePlural } = useLocalization();

const selectedPlanBillingOccurenceName = computed(() => (selectedPlanVariant.value
	? localizePlural(getBillingOccurenceDescription(selectedPlanVariant.value).name, 1)
	: ''));

const orderOverviewNote = computed(() => {
	if (!selectedPlanVariant.value) {
		return '';
	}

	const { recurringFeeInCents, currency, localizedDisplayName } = usePlanVariant(selectedPlanVariant.value, appliedDiscount.value);

	const autoRenewalNote = allowsTrialOrder.value
		? t(
			'subscribe.step.orderOverview.info.trial.autoRenewal',
			{ planName: `<strong>${localizedDisplayName.value}</strong>` },
		)
		: t(
			'subscribe.step.orderOverview.info.autoRenewal',
			{ planName: `<strong>${localizedDisplayName.value}</strong>` },
		);

	const cancelAnyTimeNote = t('subscribe.step.orderOverview.info.cancelAnyTime');

	const pricePerBillingOccurence = getFormattedPrice(recurringFeeInCents.value, currency.value, { fromCents: true });
	const billingOccurence = selectedPlanBillingOccurenceName.value;

	if (hasDiscountApplied.value && appliedDiscount.value) {
		const discountedPricePerBillingOccurenceInCents = hasDiscountApplied.value
			? getDiscountedPriceInCents({
				planVariant: selectedPlanVariant.value,
				discount: appliedDiscount.value,
			})
			: 0;
		const discountedPricePerBillingOccurence = getFormattedPrice(discountedPricePerBillingOccurenceInCents, currency.value, { fromCents: true });

		let discountNote = t('subscribe.step.orderOverview.info.pricing', {
			billingOccurence,
			pricePerBillingOccurence,
			startDate: `<strong>${DateTime.fromJSDate(getEndDate(appliedDiscount.value)).toLocaleString(DateTime.DATE_SHORT)}</strong>`,
			discountedPricePerBillingOccurence,
		});

		if (allowsTrialOrder.value) {
			discountNote = t('subscribe.step.orderOverview.info.discountedPricing', {
				billingOccurence,
				pricePerBillingOccurence,
				discountStartDate: `<strong>${endOfTrialDate.value.toLocaleString(DateTime.DATE_SHORT)}</strong>`,
				discountEndDate: `<strong>${DateTime.fromJSDate(getEndDate(appliedDiscount.value, endOfTrialDate.value.toJSDate())).toLocaleString(DateTime.DATE_SHORT)}</strong>`,
				discountedPricePerBillingOccurence,
			});
		}

		return [
			autoRenewalNote,
			discountNote,
			cancelAnyTimeNote,
		].join(' ');
	}

	if (allowsTrialOrder.value) {
		const trialNote = t('subscribe.step.orderOverview.info.pricing', {
			startDate: `<strong>${endOfTrialDate.value.toFormat('dd.MM.yyyy')}</strong>`,
			billingOccurence,
			pricePerBillingOccurence,
		});

		return [
			autoRenewalNote,
			trialNote,
			cancelAnyTimeNote,
		].join(' ');
	}

	return [
		autoRenewalNote,
		cancelAnyTimeNote,
	].join(' ');
});
</script>
