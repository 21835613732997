<template>
	<div>
		<h2 class="text-center font-semibold text-[24px] mb-8 mx-auto tablet:w-8/12 desktop:w-10/12 widescreen:w-8/12">
			{{ title }}
		</h2>
		<div class="w-full mb-8 mx-auto phablet:w-8/12 desktop:w-10/12 widescreen:w-8/12">
			<div class="desktop:flex">
				<div class="flex-grow desktop:mr-6">
					<AppInput
						v-model="couponCode"
						v-focus
						:disabled="isCodeValid"
						:label="$t('input.yourVoucherCode')"
						:error-message="error"
						icon="barcode-alt"
						class="mb-4 desktop:mb-0"
						@keypress.enter="onValidationSubmit"
					/>
				</div>
				<div>
					<AppButton
						:is-loading="waitingForValidation"
						:is-success="isCodeValid"
						data-cy="button_coupon_redeem"
						@click="onValidationSubmit"
					>
						{{ $t('button.redeemCouponCode') }}
					</AppButton>
				</div>
			</div>
		</div>
		<Transition name="fade">
			<div v-if="isCodeValid">
				<div class="w-full mb-8 mt-16 mx-auto phablet:w-8/12 desktop:w-10/12 widescreen:w-8/12">
					<CheckoutSummary no-payment />
				</div>
				<div class="mt-8 text-center flex justify-center">
					<AppButton
						data-cy="button_confirmSignup"
						size="sm"
						@click="onRedeemConfirm"
					>
						{{ $t('button.confirmOrder_coupon') }}
					</AppButton>
				</div>
			</div>
		</Transition>
	</div>
</template>

<script lang="ts" setup>
import {
	computed, onMounted, ref, watch,
} from 'vue';
import { CouponError } from '@/utilities/ErrorClasses';
import errorHandler from '@/utilities/ErrorHandler';
import CheckoutSummary from '@/components/CheckoutSummary.vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useCartStore } from '@/store/cart';
import { lookupCouponCode } from '@/api/coupon';
import { getPlanVariantById } from '@/api/planvariants';
import { Cart } from '@/models/Cart';
import { useBillwerkStore } from '@/store/billwerk';
import { errAsync, okAsync } from 'neverthrow';
import { useMainStore } from '@/store/main';
import { storeToRefs } from 'pinia';

const couponCode = ref('');
const waitingForValidation = ref(false);
const isCodeValid = ref(false);
const error = ref('');
const router = useRouter();
const cartStore = useCartStore();
const billwerkStore = useBillwerkStore();

const mainStore = useMainStore();
const { signupStyle } = storeToRefs(mainStore);

const onRedeemConfirm = () => {
	router.push({ name: 'initiatePayment' });
};

watch(couponCode, () => {
	error.value = '';
});

onMounted(() => {
	if (cartStore.cart?.M2MCouponCode) {
		couponCode.value = cartStore.cart?.M2MCouponCode;
		isCodeValid.value = true;
	}
});

const { t } = useI18n();

const onValidationSubmit = async () => {
	waitingForValidation.value = true;
	couponCode.value = couponCode.value.trim();

	const response = await lookupCouponCode(couponCode.value)
		.mapErr(() => new CouponError('notValid'))
		.andThen((couponResult) => {
			if (couponResult.redemptionState === 'GrantsLessPrivileges') {
				return errAsync(new CouponError('grantsLessPrivileges'));
			}

			if (couponResult.redemptionState === 'Disabled') {
				return errAsync(new CouponError('alreadyUsed'));
			}

			return okAsync(couponResult);
		})
		.andThen((couponResult) => getPlanVariantById(couponResult.planVariantID)
			.map((planVariantResult) => ({ couponResult, planVariantResult }))
			.mapErr(() => new CouponError('notValid')));

	waitingForValidation.value = false;

	if (response.isErr()) {
		errorHandler.report(response.error);
		isCodeValid.value = false;
		error.value = t('input.error.invalidCouponCode');
		return;
	}

	isCodeValid.value = true;

	const cart: Cart = { M2MCouponCode: couponCode.value, planVariant: response.value.planVariantResult };

	cartStore.setCartFromProduct(cart);
	mainStore.planCategory = cart.planVariant.CustomFields.category;
	billwerkStore.secretPaymentData = { bearer: 'None:None' };
};

const title = computed(() => {
	if (signupStyle.value === 'thomann') {
		return t('subscribe.step.coupon.title.thomann');
	}

	return t('subscribe.step.coupon.title.default');
});
</script>
