import { defineStore } from 'pinia';
import {
	computed, ref, type Component, readonly,
} from 'vue';
import { getLayoutComponent, type LayoutSlug } from '@/models/Layout';
import { DateTime } from 'luxon';
import { type SignupType } from '@/models/SignupType';
import { PlanCategory } from '@/models/PlanCategory';

export const useMainStore = defineStore('main', () => {
	const viewLayout = ref<LayoutSlug>('blank');
	const viewLayoutComponent = computed<Component>(() => getLayoutComponent(viewLayout.value));

	const startDate = ref(DateTime.now());
	const endOfTrialDate = computed(() => startDate.value.plus({ days: 7 }));

	const planCategory = ref<PlanCategory | null>(null);
	const selectedPlanCategory = computed(() => planCategory.value);

	const signupType = ref<SignupType>(import.meta.env.VITE_DEFAULT_SIGNUP_TYPE);

	const setSignupType = (newSignupType: SignupType | null) => {
		if (!newSignupType) {
			signupType.value = import.meta.env.VITE_DEFAULT_SIGNUP_TYPE;
			return;
		}

		if (newSignupType === signupType.value) {
			return;
		}

		if (newSignupType === 'combo') {
			planCategory.value = 'combo';
		}

		signupType.value = newSignupType;
	};

	const signupStyle = ref('default');

	return {
		viewLayout,
		viewLayoutComponent,
		startDate,
		endOfTrialDate,
		signupType: readonly(signupType),
		setSignupType,
		signupStyle,
		planCategory,
		selectedPlanCategory,
	};
});
