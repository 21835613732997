import { createApp } from 'vue';
import VueGtag from 'vue-gtag';
import axios from 'axios';
import { createGtm } from '@gtm-support/vue-gtm';
import './plugins/VeeValidate';
import * as Sentry from '@sentry/vue';
import { ClickfireTrackerVuePlugin as ClickfireTracker } from '@sunlabde/m2m-clickfire-tracker-plugin';
import LimangoLogo from '@/assets/logo_limango.svg';
import m2mLibrary, { VueTippy } from '@sunlabde/m2m-library';
import '@sunlabde/m2m-library/dist/style.css';
import { VueQueryPlugin } from '@tanstack/vue-query';
import focusDirective from './directives/focus';
import { pinia } from './plugins/pinia';
import App from './App.vue';
import i18n from './plugins/i18n';
import router from './router';
import { useAuthStore } from './store/auth';
import { CLICKFIRE_URL } from './utilities/Constants';
import logger from './plugins/Logger';
import { addSignupPortal, createSignupPortal } from './models/SignupPortal';
import './index.css';
import intercom from './plugins/intercom';
import { appProgressLoader } from './plugins/AppProgressLoader';
import { registerGlobalComponents } from './plugins/GlobalComponents';
import { plausible } from './plugins/plausible/setup';

const app = createApp(App);

axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common.Accept = 'application/json';

if (import.meta.env.VITE_ENVIRONMENT !== 'local-dev') {
	// Disable Sentry integration for local dev environment

	Sentry.init({
		app,
		dsn: 'https://e6e3525f85724bb790816afd9b623127@o440428.ingest.sentry.io/5880728',
		integrations: [
			Sentry.browserTracingIntegration({ router }),
		],
		tracesSampleRate: 1.0,
		tracePropagationTargets: [/^https:\/\/api\.music2me\.com/, /^\//],
		environment: import.meta.env.VITE_ENVIRONMENT,
		release: __COMMIT_REF__,
		denyUrls: ['local.signup.music2me.com'],
	});
}

app.use(VueGtag, {
	config: {
		id: 'UA-21616160-1',
	},
}, router);

app.use(
	createGtm({
		id: 'GTM-K5GGW2Z',
		enabled: true,
		debug: false,
		vueRouter: router,
	}),
);

app.use(intercom, {
	app_id: import.meta.env.VITE_INTERCOM_APP_ID,
});

app.use(plausible);
app.use(VueTippy, {
	directive: 'tippy',
	defaultProps: {
		theme: 'info',
		animation: 'shift-toward',
		arrow: true,
	},
});
app.use(pinia);
app.use(ClickfireTracker, { router, url: CLICKFIRE_URL });
app.use(router);
app.use(appProgressLoader, { router });
app.use(i18n);
app.use(logger);
app.use(VueQueryPlugin);
app.use(m2mLibrary, { i18n });

app.directive('focus', focusDirective);

registerGlobalComponents(app);

const {
	setupAuthenticationHooks,
} = useAuthStore();

setupAuthenticationHooks();

addSignupPortal([
	createSignupPortal('limango', { logo: LimangoLogo, trial: false, hasPlans: true }),
	createSignupPortal('neujahr21', { trial: false, hasPlans: false }),
	createSignupPortal('cyberweek', {
		trial: false,
		discountCode: import.meta.env.VITE_DISCOUNT_CYBERWEEK_70,
	}),
	createSignupPortal('launchMonth', { trial: true, hasPlans: true }),
	createSignupPortal('cyberweek24', { trial: true, hasPlans: true }),
	createSignupPortal('cyberspecial24', { trial: true, hasPlans: true }),
	createSignupPortal('ostern24', { trial: true, hasPlans: true }),
	createSignupPortal('plussies', { trial: true, hasPlans: true }),
]);

router.isReady().then(async () => {
	app.mount('#app');
});
