import PlancategoryComboImage from '@/assets/plancategory-combo-image.jpg';
import PlancategoryGuitarImage from '@/assets/plancategory-guitar-image.jpg';
import PlancategoryPianoImage from '@/assets/plancategory-piano-image.jpg';
import PlancategoryDrumsImage from '@/assets/plancategory-drums-image.jpg';

export type PlanCategory = 'combo' | 'guitar' | 'piano' | 'drums' | 'plus';

export const planCategoryImageMap: Record<PlanCategory, string> = {
	combo: PlancategoryComboImage,
	plus: PlancategoryComboImage,
	guitar: PlancategoryGuitarImage,
	piano: PlancategoryPianoImage,
	drums: PlancategoryDrumsImage,
};
