import type { Composer } from 'vue-i18n';
import { useI18n } from 'vue-i18n';

export type Localization = (composer: Composer['t']) => ReturnType<Composer['t']>;
export type LocalizationPlural = (composer: Composer['t'], plural: number) => ReturnType<Composer['t']>;

export const useLocalization = () => {
	const { t } = useI18n();

	const localize = (localization: Localization | string) => (typeof localization === 'string' ? localization : localization(t));
	const localizePlural = (localization: LocalizationPlural, plural: number) => localization(t, plural);

	return {
		localize,
		localizePlural,
	};
};
