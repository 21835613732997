import { SignupStep } from './SignupStep';

export type SignupType = 'default' | 'coupon' | 'combo' | 'plus';

export type SignupTypeMeta<T extends SignupType = SignupType> = {
	id: T;
	name: string;
	stepBundle: SignupStep[];
	defaultStepIndex: number;
}

export const signupTypeMetaMap: { [K in SignupType]: SignupTypeMeta<K> } = {
	default: {
		id: 'default',
		name: 'Default',
		stepBundle: ['instrument-selection', 'plan-selection', 'order-overview'],
		defaultStepIndex: 0,
	},
	plus: {
		id: 'plus',
		name: 'Plus',
		stepBundle: ['plan-category-selection', 'payment-selection', 'order-overview'],
		defaultStepIndex: 0,
	},
	coupon: {
		id: 'coupon',
		name: 'Voucher',
		stepBundle: ['reset', 'redeem-voucher'],
		defaultStepIndex: 1,
	},
	combo: {
		id: 'combo',
		name: 'Family',
		stepBundle: ['reset', 'plan-selection', 'order-overview'],
		defaultStepIndex: 1,
	},
};

export const isSignupType = (input: string): input is SignupType => Object.keys(signupTypeMetaMap).includes(input);

export const getSignupTypeMeta = <T extends SignupType = SignupType>(signupType: T): SignupTypeMeta<T> => signupTypeMetaMap[signupType];

export const getPlanSelectionIndexOrDefault = (signupType: SignupType): number => {
	const signupTypeMeta = getSignupTypeMeta(signupType);
	return signupTypeMeta.stepBundle.findIndex((step) => ['payment-selection', 'plan-selection'].includes(step) || signupTypeMeta.defaultStepIndex);
};
