import { BillingOccurence } from './BillingOccurence';
import { Discount } from './Discounts';
import {
	getBillingOccurence,
	getBillingOccurenceDescription, getRecurringFeeInCents, PlanVariant,
} from './PlanVariant';

export interface Product {
	planVariant: PlanVariant;
	discount?: Discount;
}

export const getUniqueBillingOcurrences = (products: Product[]): BillingOccurence[] => products.reduce((billingOccurences, product) => {
	const billingOccurence = getBillingOccurence(product.planVariant);
	return billingOccurence ? [...new Set([billingOccurence, ...billingOccurences])] : billingOccurences;
}, [] as BillingOccurence[]);

export const getDiscountedPriceInCents = (product: Product): number => {
	if (product.discount) {
		return getRecurringFeeInCents(product.planVariant) - (getRecurringFeeInCents(product.planVariant) * (product.discount.Effect.ReductionPercent / 100));
	}

	return getRecurringFeeInCents(product.planVariant);
};

export const sortProductsByMonth = (products: Product[]): Product[] => [...products].sort((productA, productB) => {
	const billingOccurenceA = getBillingOccurenceDescription(productA.planVariant);
	const billingOccurenceB = getBillingOccurenceDescription(productB.planVariant);

	if (billingOccurenceA.months < billingOccurenceB.months) {
		return -1;
	}

	if (billingOccurenceA.months > billingOccurenceB.months) {
		return 1;
	}

	return 0;
});
