<template>
	<div>
		<p
			v-if="displayTitle"
			class="font-bold"
		>
			{{ displayTitle }}
		</p>
		<p class="font-normal">
			{{ displayText }}
		</p>
	</div>
</template>

<script lang="ts" setup>
import { Localization, useLocalization } from '@/models/Localization';
import { computed, PropType } from 'vue';

const props = defineProps({
	title: {
		type: [String, Function] as PropType<Localization>,
		default: '',
	},
	text: {
		type: Function as PropType<Localization>,
		required: true,
	},
});

const { localize } = useLocalization();

const displayTitle = computed((): string => {
	if (typeof props.title === 'function') {
		return localize(props.title);
	}

	return props.title;
});

const displayText = computed((): string => {
	if (typeof props.text === 'function') {
		return localize(props.text);
	}

	return props.text;
});
</script>
