import PaymentPaypal from '@/components/payment/PaymentPaypal.vue';
import PaymentCreditcard from '@/components/payment/PaymentCreditcard.vue';
import PaymentEC from '@/components/payment/PaymentEC.vue';
import { SupportedLocale } from '@/models/Language';
import PaypalImage from '@/assets/payment-paypal.svg';
import CreditCardImage from '@/assets/payment-card.svg';
import ELVImage from '@/assets/payment-ec.svg';
import { Localization } from '@/models/Localization';

export interface PaymentMethodSignup {
	ID: string;
	name: Localization;
	image: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	view: any;
	languages: SupportedLocale[];
}

const paymentMethods: PaymentMethodSignup[] = [
	{
		ID: 'PayPal',
		name: (t) => t('paymentMethod.paypal'),
		image: PaypalImage,
		view: PaymentPaypal,
		languages: ['de', 'en', 'es'],
	},
	{
		ID: 'CreditCard:Adyen',
		name: (t) => t('paymentMethod.creditcard'),
		image: CreditCardImage,
		view: PaymentCreditcard,
		languages: ['de', 'en', 'es'],
	},
];

// Hotfix for hiding ELV for everyone who has not a german browser
if (/^de\b/.test(navigator.language)) {
	paymentMethods.push({
		ID: 'Debit:Heidelpay',
		name: (t) => t('paymentMethod.EC'),
		image: ELVImage,
		view: PaymentEC,
		languages: ['de'],
	});
}

export default paymentMethods;
