import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import i18n from '@/plugins/i18n';
import { LanguageDictionary, supportedLanguages } from '@/models/Language';

export const useLanguage = () => {
	const router = useRouter();
	const route = useRoute();

	const selectedLanguage = computed(() => supportedLanguages[i18n.global.locale]);

	const composeLinkWithNewLocale = (locale: string, currentRoute = route) => {
		const newRoute = { ...currentRoute, params: { ...currentRoute.params, lang: locale } };

		return router.resolve(newRoute).href;
	};

	const availableLanguages = computed((): LanguageDictionary => supportedLanguages);

	const selectedLanguageCode = computed(() => selectedLanguage.value.code);

	return {
		selectedLanguage,
		selectedLanguageCode,
		composeLinkWithNewLocale,
		availableLanguages,
	};
};
