import { getPortalCarts } from '@/api/portal';
import { SignupPortal, portalAllowsTrialOrder } from '@/models/SignupPortal';
import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { getPlanVariantId, getPlanVariantIds } from '@/models/PortalCart';
import { getPlanVariantsById } from '@/api/planvariants';
import { useLanguage } from '@/composables/useLanguage';
import { getBillingOccurence, getComboComparisonByBillingOccurence, getPlanVariantsForLanguage } from '@/models/PlanVariant';
import { Product, getUniqueBillingOcurrences } from '@/models/Product';
import { lookupDiscountCodeInCarts } from '@/api/discount';
import { InternalOrderCart } from '@/models/InternalOrder';
import { BillingOccurence } from '@/models/BillingOccurence';
import { PREFERRED_BILLING_OCCURENCE } from '@/utilities/Constants';
import { PlanType } from '@/models/PlanType';
import { getInstrumentsForLanguage, getInstrumentsForPlanCategory } from '@/utilities/Instrument';
import { SupportedLocale } from '@/models/Language';
import { useAuthStore } from './auth';
import { useUserStore } from './user';

export const useProductsStore = defineStore('products', () => {
	const authStore = useAuthStore();
	const userStore = useUserStore();

	const portal = ref<SignupPortal>();

	const portalId = computed(() => portal.value?.name ?? import.meta.env.VITE_DEFAULT_PORTAL);

	const selectedBillingOccurence = ref<BillingOccurence>(PREFERRED_BILLING_OCCURENCE);

	const selectedPlanType = ref<PlanType>();

	const { data: portalCategoryCarts, isFetched } = useQuery(['portal', portalId], async () => {
		const response = await getPortalCarts({
			portalId: portalId.value,
			countryCode: authStore.user?.country ?? 'de',
		}).unwrapOr(null);

		return response;
	});

	const planVariantsToFetch = computed(() => (portalCategoryCarts.value ? getPlanVariantIds(portalCategoryCarts.value) : []));

	const { selectedLanguage } = useLanguage();

	const { data: planVariantsData } = useQuery(
		['planVariants', planVariantsToFetch],
		async () => getPlanVariantsById(planVariantsToFetch.value).unwrapOr([]),
		{
			initialData: [],
			enabled: computed(() => planVariantsToFetch.value.length > 0),
			refetchOnWindowFocus: false,
		},
	);

	const planVariantsForLanguage = computed(() => getPlanVariantsForLanguage(planVariantsData.value, selectedLanguage.value.code));

	const internalCartsWithDiscounts = computed(() => {
		if (!portalCategoryCarts.value) {
			return [];
		}

		return Object.entries(portalCategoryCarts.value)
			.map(([, portalCarts]) => portalCarts)
			.flat()
			.map((portalCart) => {
				const planVariantId = getPlanVariantId(portalCart);
				const planVariant = planVariantsForLanguage.value.find((p) => p.Id === planVariantId);

				if (!planVariant) {
					return null;
				}

				const discountCode = portal.value && portal.value.options.discountCode ? portal.value.options.discountCode : portalCart.billwerkCouponCode;

				if (!discountCode) {
					return null;
				}

				const cart: InternalOrderCart = { PlanVariantID: planVariantId, CouponCode: discountCode, DiscountSubscriptions: [] };

				return cart;
			})
			.filter((cart): cart is InternalOrderCart => cart !== null);
	});

	const { data: discountsData } = useQuery(
		['discounts', internalCartsWithDiscounts],
		async () => {
			if (!userStore.billwerkCustomerId) {
				return [];
			}

			const result = (await lookupDiscountCodeInCarts(
				userStore.billwerkCustomerId,
				internalCartsWithDiscounts.value,
			)).unwrapOr([]);

			return result;
		},
		{
			initialData: [],
			enabled: computed(() => internalCartsWithDiscounts.value.length > 0),
			refetchOnWindowFocus: false,
		},
	);

	const availableInstruments = computed(() => getInstrumentsForLanguage(selectedLanguage.value.code as SupportedLocale).map((i) => i.id));

	const products = computed<Product[]>(() => {
		if (!portalCategoryCarts.value) {
			return [];
		}

		return Object.entries(portalCategoryCarts.value)
			.map(([, portalCarts]) => portalCarts)
			.flat()
			.map((portalCart) => {
				const planVariantId = getPlanVariantId(portalCart);
				const planVariant = planVariantsForLanguage.value.find((p) => p.Id === planVariantId);

				if (!planVariant) {
					return null;
				}

				const discountItem = discountsData.value.find((item) => !!item.Targets[planVariant.Id]);

				const product: Product = {
					planVariant,
					discount: discountItem,
				};

				return product;
			})
			.filter((product): product is Product => product !== null)
			.filter(({ planVariant }) => {
				const instruments = getInstrumentsForPlanCategory(planVariant.CustomFields.category);
				return availableInstruments.value.some((instrument) => instruments.includes(instrument));
			});
	});

	const getProductsFromCategory = (category: string): Product[] => products.value
		.filter((product) => product.planVariant.CustomFields.category === category);

	const comboComparisonByBillingOccurence = computed(() => getComboComparisonByBillingOccurence(planVariantsForLanguage.value));

	const allowsTrialOrder = computed(() => (userStore.qualifiesForTrial ? portalAllowsTrialOrder(portal.value?.name ?? 'default') : false));

	const availableBillingOccurences = computed(() => getUniqueBillingOcurrences(products.value));

	watch(availableBillingOccurences, (billingOccurences) => {
		if (billingOccurences.some((billingOccurence) => billingOccurence === selectedBillingOccurence.value)) return;

		if (billingOccurences.some((billingOccurence) => billingOccurence === PREFERRED_BILLING_OCCURENCE)) {
			selectedBillingOccurence.value = PREFERRED_BILLING_OCCURENCE;
		} else {
			[selectedBillingOccurence.value] = billingOccurences;
		}
	}, { immediate: true });

	const productsForBillingOccurence = computed(() => products.value.filter((product) => {
		const billingOccurence = getBillingOccurence(product.planVariant);
		return billingOccurence === selectedBillingOccurence.value;
	}));

	const availablePlanTypes = computed(() => [...new Set(productsForBillingOccurence.value.map((product) => product.planVariant.CustomFields.planType))]);

	return {
		portal,
		carts: products,
		data: portalCategoryCarts,
		planVariantsToFetch,
		planVariants: planVariantsForLanguage,
		getProductsFromCategory,
		comboComparisonByBillingOccurence,
		allowsTrialOrder,
		discountsData,
		isFetched,
		availablePlanTypes,
		availableBillingOccurences,
		selectedBillingOccurence,
		productsForBillingOccurence,
		selectedPlanType,
	};
});
