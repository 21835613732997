import { DateTime } from 'luxon';
import i18n from '@/plugins/i18n';
import { Discount, getEndDate } from './Discounts';
import { TranslationString } from './Language';
import { getBillingOccurenceDescription, getRecurringFeeInCents, PlanVariant } from './PlanVariant';

export interface PaymentPeriod {
	label: TranslationString;
	priceInCents: number;
	reductionPercent?: number;
	startDate?: Date;
}

export interface PaymentPeriodOptions {
	planVariant: PlanVariant;
	trialEndDate?: Date;
	discount?: Discount;
}

export const getPrice = (paymentPeriod: PaymentPeriod): number => {
	if (paymentPeriod.reductionPercent) {
		return paymentPeriod.priceInCents - (paymentPeriod.priceInCents * (paymentPeriod.reductionPercent / 100));
	}

	return paymentPeriod.priceInCents;
};

export const getDefaultPaymentPeriod = (
	{ planVariant }: PaymentPeriodOptions,
	startDate?: Date,
): PaymentPeriod => {
	const billingOccurence = getBillingOccurenceDescription(planVariant);

	if (startDate) {
		return {
			label() {
				return i18n.global.t('price.priceWithBillingOccurence', { billingOccurence: billingOccurence.name(i18n.global.t, 1) });
			},
			priceInCents: getRecurringFeeInCents(planVariant),
			startDate,
		};
	}

	return {
		label() {
			return i18n.global.t('price.totalWithBillingOccurence', { billingOccurence: billingOccurence.name(i18n.global.t, 1) });
		},
		priceInCents: getRecurringFeeInCents(planVariant),
	};
};

export const getTrialPaymentPeriod = (): PaymentPeriod => ({
	label() {
		return i18n.global.t('price.totalWithBillingOccurence', { billingOccurence: i18n.global.tc('billingOccurence.trial.name') });
	},
	priceInCents: 0,
});

export const getDiscountPaymentPeriod = (
	{ planVariant, discount }: PaymentPeriodOptions & Required<Pick<PaymentPeriodOptions, 'discount'>>,
	startDate?: Date,
): PaymentPeriod => {
	const billingOccurence = getBillingOccurenceDescription(planVariant);

	return {
		label() {
			return i18n.global.t('price.discountedPriceWithBillingOccurence', { billingOccurence: billingOccurence.name(i18n.global.t, 1) });
		},
		reductionPercent: discount.Effect.ReductionPercent,
		priceInCents: getRecurringFeeInCents(planVariant),
		startDate,
	};
};

export const getPaymentPeriodDurationNote = (paymentPeriods: PaymentPeriod[], index: number): TranslationString | null => {
	const currentPeriod = paymentPeriods[index];

	if (!currentPeriod || !currentPeriod.startDate) {
		return null;
	}

	const nextPeriod = paymentPeriods[index + 1];
	const nextPeriodStartDate = nextPeriod ? nextPeriod.startDate : undefined;
	const formattedStartDate = DateTime.fromJSDate(currentPeriod.startDate).toLocaleString(DateTime.DATE_SHORT);

	if (nextPeriodStartDate) {
		const formattedEndDate = DateTime.fromJSDate(nextPeriodStartDate).toLocaleString(DateTime.DATE_SHORT);

		return () => i18n.global.t('generic.date.fromTo', [formattedStartDate, formattedEndDate]);
	}

	return () => i18n.global.t('generic.date.from', [formattedStartDate]);
};

export const hasTrial = (options: PaymentPeriodOptions): options is PaymentPeriodOptions & Required<Pick<PaymentPeriodOptions, 'trialEndDate'>> => !!options.trialEndDate;

export const hasDiscount = (options: PaymentPeriodOptions): options is PaymentPeriodOptions & Required<Pick<PaymentPeriodOptions, 'discount'>> => !!options.discount;

export const getPaymentPeriods = (options: PaymentPeriodOptions): PaymentPeriod[] => {
	if (hasTrial(options) && hasDiscount(options)) {
		const trialPeriod = getTrialPaymentPeriod();
		const discountPeriod = getDiscountPaymentPeriod(options, options.trialEndDate);
		const afterDiscountPeriod = getDefaultPaymentPeriod(options, getEndDate(options.discount, discountPeriod.startDate));

		return [
			trialPeriod,
			discountPeriod,
			afterDiscountPeriod,
		];
	}

	if (hasTrial(options)) {
		const trialPeriod = getTrialPaymentPeriod();
		const afterTrialPeriod = getDefaultPaymentPeriod(options, options.trialEndDate);

		return [
			trialPeriod,
			afterTrialPeriod,
		];
	}

	if (hasDiscount(options)) {
		const discountPeriod = getDiscountPaymentPeriod(options);
		const afterDiscountPeriod = getDefaultPaymentPeriod(options, getEndDate(options.discount));

		return [
			discountPeriod,
			afterDiscountPeriod,
		];
	}

	return [getDefaultPaymentPeriod(options)];
};
