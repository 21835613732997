<template>
	<TheAside>
		<template #body>
			<transition
				name="fade-up"
				appear
			>
				<h2 class="text-4xl leading-[1.1] widescreen:text-[54px] mb-4 hidden widescreen:block font-bold">
					{{ $t('aside.title.welcome') }}
				</h2>
			</transition>
			<transition
				name="fade-up"
				appear
			>
				<p class="text-sm leading-[1.75] hidden widescreen:block">
					{{ $t('aside.desc.default') }}
				</p>
			</transition>
		</template>
		<template #footer>
			<transition
				name="fade-up"
				mode="out-in"
				appear
			>
				<TrialTimeline
					v-if="allowsTrialOrder && hasPickedPlanCategory && furthestStepIndex > 0"
					class="mt-12 widescreen:mt-0"
				/>
				<div
					v-else
					class="text-xl font-bold leading-normal border-l-[3px] border-solid border-primary-400 pl-4"
				>
					<p>{{ $t('aside.quote.courses') }}</p>
				</div>
			</transition>
		</template>
	</TheAside>
</template>

<script lang="ts" setup>
import TheAside from '@/components/TheAside.vue';
import { computed } from 'vue';
import { useSubscribeStore } from '@/store/subscribe';
import { storeToRefs } from 'pinia';
import { useProductsStore } from '@/store/products';
import { useMainStore } from '@/store/main';
import TrialTimeline from './TrialTimeline.vue';

const subscribeStore = useSubscribeStore();
const furthestStepIndex = computed(() => subscribeStore.furthestStepIndex);

const mainStore = useMainStore();
const { planCategory } = storeToRefs(mainStore);

const productsStore = useProductsStore();
const { allowsTrialOrder } = storeToRefs(productsStore);

const hasPickedPlanCategory = computed(() => !!planCategory.value);
</script>
