import TheStepsFooter from '@/components/TheStepsFooter.vue';
import CreationAside from '@/components/CreationAside.vue';
import SubscribeAside from '@/components/SubscribeAside.vue';
import ViewWrapper from '@/components/ViewWrapper.vue';
import ViewSignup from '@/views/ViewSignup.vue';
import {
	SignupType, getPlanSelectionIndexOrDefault, isSignupType, getSignupTypeMeta,
} from '@/models/SignupType';
import { getPortalByName, isValidPortal } from '@/models/SignupPortal';
import errorHandler from '@/utilities/ErrorHandler';
import i18n from '@/plugins/i18n';
import { useAuthStore } from '@/store/auth';
import { RouteRecordRaw } from 'vue-router';
import { getLocationQueryValue } from '@/router/helpers';
import { useCartStore } from '@/store/cart';
import { DiscountErrorCodes } from '@/enums/ErrorCodes';
import { useUserStore } from '@/store/user';
import { lookupDiscountCodeInCart } from '@/api/discount';
import { useProductsStore } from '@/store/products';
import { getPlanVariantById } from '@/api/planvariants';
import { Product } from '@/models/Product';
import { useMainStore } from '@/store/main';
import ViewSignupWithSocialLogin from '@/views/ViewSignupWithSocialLogin.vue';
import { FeatureFlag, isFeatureFlagEnabled } from '@/models/FeatureFlag';
import ViewSocialCallback from '@/views/ViewSocialCallback.vue';

export const routes: RouteRecordRaw[] = [
	{
		path: '/:lang([a-z]{2})',
		component: ViewWrapper,

		children: [
			{
				path: '',
				name: 'signup',
				component: isFeatureFlagEnabled(FeatureFlag.SOCIAL_LOGIN) ? ViewSignupWithSocialLogin : ViewSignup,
				meta: {
					title: () => 'Signup',
					layout: 'signup',
					aside: CreationAside,
					requiresGuest: true,
				},
			},
			{
				path: 'social/callback',
				name: 'SocialCallback',
				component: ViewSocialCallback,
				meta: {
					title: () => 'Login',
					layout: 'blank',
					requiresGuest: true,
				},
			},
			{
				path: 'subscribe/redeem-coupon',
				name: 'redeem-coupon',
				redirect: {
					name: 'subscribe',
					query: {
						type: 'coupon',
						step: 2,
					},
				},
				meta: {
					requiresAuth: true,
					title: () => 'Redeem Coupon',
				},
			},
			{
				path: 'subscribe/comboplan',
				name: 'signup-comboplan',
				redirect: {
					name: 'subscribe',
					query: {
						type: 'combo',
						step: 2,
						portal: 'default',
					},
				},
			},
			{
				path: 'subscribe',
				name: 'subscribe',
				component: () => import('@/views/ViewSubscribe.vue'),
				meta: {
					title: () => 'Subscribe',
					layout: 'signup',
					footer: TheStepsFooter,
					aside: SubscribeAside,
					requiresAuth: true,
				},
				beforeEnter: async (to, from, next) => {
					const userStore = useUserStore();
					const mainStore = useMainStore();
					const productsStore = useProductsStore();

					try {
						await userStore.fetchSubscriptions();

						if (to.query.portal && isValidPortal(to.query.portal)) {
							const portal = getPortalByName(to.query.portal);
							productsStore.portal = portal;

							if (portal?.options.preselectedCategoryId) {
								mainStore.planCategory = portal.options.preselectedCategoryId;

								const signupType: SignupType = portal.options.defaultSignupType ?? import.meta.env.VITE_DEFAULT_SIGNUP_TYPE;
								const planSelectionIndex = getPlanSelectionIndexOrDefault(signupType);
								const planSelectionStep = planSelectionIndex + 1;

								if (Number(to.query.step) === planSelectionStep) {
									next();
									return;
								}

								if (Number(to.query.step) !== planSelectionStep) {
									next({ name: 'subscribe', query: { ...to.query, step: planSelectionStep }, params: { lang: to.params.lang } });
									return;
								}
							}
						}

						const planVariantId = getLocationQueryValue(to, 'planVariantID');

						if (planVariantId) {
							try {
								const planVariant = await getPlanVariantById(planVariantId).unwrapOr(null);

								if (!planVariant) {
									throw new Error('query.invalid.planVariantID');
								}

								const product: Product = {
									planVariant,
								};

								mainStore.planCategory = planVariant.CustomFields.category;

								const signupType: SignupType = productsStore.portal?.options.defaultSignupType ?? import.meta.env.VITE_DEFAULT_SIGNUP_TYPE;
								const planSelectionIndex = getPlanSelectionIndexOrDefault(signupType);

								const discountCode = getLocationQueryValue(to, 'discountCode');

								if (discountCode && userStore.billwerkCustomerId) {
									const discountItem = await lookupDiscountCodeInCart(userStore.billwerkCustomerId, {
										PlanVariantID: planVariant.Id,
										CouponCode: discountCode,
										DiscountSubscriptions: [],
									}).unwrapOr(null);

									if (discountItem) {
										product.discount = discountItem;
									} else {
										errorHandler.report(new Error(DiscountErrorCodes.BILLWERK_INVALID_COUPON_CODE));
										delete to.query.discountCode;
									}
								}

								const cartStore = useCartStore();
								const discountStartDate = productsStore.allowsTrialOrder ? mainStore.endOfTrialDate : undefined;
								cartStore.setCartFromProduct(product, discountStartDate);

								productsStore.selectedPlanType = product.planVariant.CustomFields.planType;

								if (Number(to.query.step) !== (planSelectionIndex + 1)) {
									next({ name: 'subscribe', query: { ...to.query, step: planSelectionIndex + 1 }, params: { lang: to.params.lang } });
									return;
								}
							} catch (error) {
								delete to.query.planVariantID;
								delete to.query.discountCode;
								next({ name: 'subscribe', query: { ...to.query }, params: { lang: to.params.lang } });
								return;
							}
						} else if (to.query.type) {
							const signupTypeFromRouteQuery = getLocationQueryValue(to, 'type');
							const signupTypeFromRouteQueryOrPortalFallback = signupTypeFromRouteQuery && isSignupType(signupTypeFromRouteQuery)
								? signupTypeFromRouteQuery
								: productsStore.portal?.options.defaultSignupType;
							const signupType = signupTypeFromRouteQueryOrPortalFallback ?? import.meta.env.VITE_DEFAULT_SIGNUP_TYPE;
							const signupTypeMeta = getSignupTypeMeta(signupType);

							if (signupTypeFromRouteQuery && Number(to.query.step) !== signupTypeMeta.defaultStepIndex + 1) {
								next({
									name: 'subscribe',
									query: {
										...to.query,
										step: signupTypeMeta.defaultStepIndex + 1,
										type: to.query.type,
									},
									params: { lang: to.params.lang },
								});
								return;
							}
						} else if (to.query.step && Number(to.query.step) !== 1) {
							next({ name: 'subscribe', query: { ...to.query, step: 1 }, params: { lang: to.params.lang } });
							return;
						}

						next();
					} catch (error) {
						console.error(error);
						const authStore = useAuthStore();

						authStore.removeAuthentication();
						next('');
					}
				},
			},
			{
				path: 'subscribe/initiatePayment',
				name: 'initiatePayment',
				props: true,
				component: () => import('@/views/ViewInitiatePayment.vue'),
				meta: {
					title: () => 'Initialize Payment',
					requiresAuth: true,
					layout: 'blank',
				},
			},
			{
				path: 'subscribe/finalizePayment',
				name: 'finalizePayment',
				props: true,
				component: () => import('@/views/ViewFinalizePayment.vue'),
				meta: {
					title: () => 'Finalize Payment',
					requiresAuth: true,
					layout: 'blank',
				},
			},
			{
				path: 'login',
				name: 'login',
				meta: {
					layout: 'blank',
					title: () => 'Login',
				},
				props: () => ({ location: `${import.meta.env.VITE_APP_URL}/${i18n.global.locale}/login` }),
				component: () => import('@/views/ViewRedirect.vue'),
			},
			{
				path: 'logout',
				name: 'SignOut',
				meta: {
					requiresAuth: true,
					layout: 'blank',
					title: () => 'Signout',
				},
				props: () => ({ location: `${import.meta.env.VITE_APP_URL}/${i18n.global.locale}/logout` }),
				component: () => import('@/views/ViewRedirect.vue'),
			},
			{
				path: 'thomann',
				name: 'ThomannSignup',
				meta: {
					layout: 'blank',
					title: () => 'Thomann Signup',
				},
				beforeEnter: (to, from, next) => {
					const mainStore = useMainStore();
					mainStore.signupStyle = 'thomann';

					next({
						name: 'subscribe',
						query: {
							type: 'coupon',
						},
						params: { lang: to.params.lang },
					});
				},
				// TODO: check if behavior is still the same
				component: () => import('@/views/ViewRedirect.vue'),
			},
			{
				path: 'redirect-to-app',
				name: 'Home',
				meta: {
					layout: 'blank',
					title: 'Home',
				},
				props: () => ({ location: `${import.meta.env.VITE_APP_URL}/${i18n.global.locale}` }),
				component: () => import('@/views/ViewRedirect.vue'),
			},
			{
				path: 'redirect-to-marketing',
				name: 'Marketing',
				meta: {
					layout: 'blank',
					title: 'Marketing',
				},
				props: () => ({ location: `${process.env.VUE_APP_MARKETING_URL}/${i18n.global.locale}` }),
				component: () => import('@/views/ViewRedirect.vue'),
			},
			{
				path: 'redirect/:location',
				name: 'Redirect',
				meta: {
					layout: 'blank',
					title: 'Redirect',
				},
				props: true,
				component: () => import('@/views/ViewRedirect.vue'),
			},
		],
	},
	{
		path: '/version',
		name: 'Version',
		meta: {
			layout: 'blank',
			title: 'Version',
			skipLocaleCheck: true,
		},
		component: () => import('@/views/ViewVersion.vue'),
	},
	{
		path: '/:pathMatch(.*)*',
		redirect: { name: 'signup', params: { lang: i18n.global.locale } },
	},
];
