import type { Localization, LocalizationPlural } from './Localization';

export type BillingOccurence = 'Monthly' | 'Trimonthly' | 'Biannually' | 'Yearly';

export interface BillingOccurenceDescription {
	id: BillingOccurence;
	name: LocalizationPlural;
	unit: Localization;
	months: number;
}

export const billingOccurences: Record<BillingOccurence, BillingOccurenceDescription> = {
	Monthly: {
		id: 'Monthly',
		name: (t, plural) => t('billingOccurence.month.name', plural),
		unit: (t) => t('billingOccurence.month.unit'),
		months: 1,
	},
	Trimonthly: {
		id: 'Trimonthly',
		name: (t, plural) => t('billingOccurence.trimonth.name', plural),
		unit: (t) => t('billingOccurence.trimonth.unit'),
		months: 3,
	},
	Biannually: {
		id: 'Biannually',
		name: (t, plural) => t('billingOccurence.biannual.name', plural),
		unit: (t) => t('billingOccurence.biannual.unit'),
		months: 6,
	},
	Yearly: {
		id: 'Yearly',
		name: (t, plural) => t('billingOccurence.annual.name', plural),
		unit: (t) => t('billingOccurence.annual.unit'),
		months: 12,
	},
};

export const getBillingOccurenceByMonths = (months: number): BillingOccurence | null => {
	const billingOccurence = Object.values(billingOccurences).find(
		(description) => description.months === months,
	);

	if (!billingOccurence) {
		return null;
	}

	return billingOccurence.id;
};
