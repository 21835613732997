import * as Sentry from '@sentry/vue';
import i18n from '@/plugins/i18n';
import { PlanCategory } from '@/models/PlanCategory';
import {
	BillingOccurence, BillingOccurenceDescription, billingOccurences, getBillingOccurenceByMonths,
} from './BillingOccurence';
import { BillwerkPlanVariant } from './Billwerk';
import { ISOCurrency } from './Currency';
import { FeatureFlag, isFeatureFlagEnabled } from './FeatureFlag';
import { PlanType } from './PlanType';

export const SUPPORTED_BILLING_PERIOD_UNITS = [
	'Month',
	'Year',
] as const;

export type PlanVariant = BillwerkPlanVariant & {
	// TODO: Remove optional for isoCurrency once implemention in content api is done
	isoCurrency?: ISOCurrency;
	CustomFields: {
		availableLanguages?: string;
		category: PlanCategory;
		planType: PlanType;
	}
}

export interface PriceComparison {
	savingsInPercent: number;
	savingsInCents: number;
}

export const getISOCurrency = (plan: PlanVariant): ISOCurrency => {
	// TODO: Remove check for isoCurrency once implemention in content api is done
	if (!plan.isoCurrency) {
		let currency = 'EUR' as ISOCurrency;

		if (i18n.global.locale === 'en') {
			currency = 'USD';
		}

		Sentry.captureMessage('PlanVariant had no isoCurrency set, fallback to locale.', {
			extra: {
				planVariantId: plan.Id,
				locale: i18n.global.locale,
				fallbackCurrency: currency,
			},
		});

		return currency;
	}
	return plan.isoCurrency;
};

export const isSupportedBillingPeriodUnit = (input: string): input is typeof SUPPORTED_BILLING_PERIOD_UNITS[number] => SUPPORTED_BILLING_PERIOD_UNITS.includes(input as typeof SUPPORTED_BILLING_PERIOD_UNITS[number]);

export const getRecurringFee = (plan: PlanVariant): number => plan.RecurringFee ?? 0;

export const getRecurringFeeInCents = (plan: PlanVariant): number => getRecurringFee(plan) * 100;

export const getQuantityInMonths = (plan: PlanVariant): number => {
	if (
		!plan.BillingPeriod.Unit
        || !plan.BillingPeriod.Quantity
        || !isSupportedBillingPeriodUnit(plan.BillingPeriod.Unit)
	) {
		throw new Error('Billing Period Unit is not supported');
	}

	if (plan.BillingPeriod.Unit === 'Year') {
		return plan.BillingPeriod.Quantity * 12;
	}

	return plan.BillingPeriod.Quantity;
};

export const getCostsPerMonthInCents = (planVariant: PlanVariant): number => getRecurringFeeInCents(planVariant) / getQuantityInMonths(planVariant);

export const getBillingOccurence = (plan: PlanVariant): BillingOccurence => {
	const qunantityInMonths = getQuantityInMonths(plan);
	const billingOccurence = getBillingOccurenceByMonths(qunantityInMonths);

	if (!billingOccurence) {
		throw new Error('Billing Occurence is not supported');
	}

	return billingOccurence;
};

export const getBillingOccurenceDescription = (plan: PlanVariant): BillingOccurenceDescription => billingOccurences[getBillingOccurence(plan)];

export const getCombinedPriceInCents = (planVariants: PlanVariant[]): number => planVariants
	.map(getRecurringFeeInCents)
	.reduce((a, b) => a + b, 0);

export const getMaxComparisonWithCategory = (planVariants: PlanVariant[], category: PlanCategory): PriceComparison => {
	const originPlan = planVariants.find((plan) => plan.CustomFields.category === category);

	if (!originPlan) {
		return {
			savingsInCents: 0,
			savingsInPercent: 0,
		};
	}

	const pricePerOccurenceInCentsComboPass = getRecurringFeeInCents(originPlan);
	const singleCategoryPlans = planVariants.filter((plan) => plan.CustomFields.category !== category);
	const combinedPriceInCents = getCombinedPriceInCents(singleCategoryPlans);

	const savingsInCents = combinedPriceInCents - pricePerOccurenceInCentsComboPass;
	const savingsInPercent = Math.floor(100 - (pricePerOccurenceInCentsComboPass / combinedPriceInCents) * 100);

	return {
		savingsInCents,
		savingsInPercent,
	};
};

export const getMaxComboComparison = (planVariants: PlanVariant[]): PriceComparison => getMaxComparisonWithCategory(planVariants, 'combo');

export const groupPlansByBillingOccurence = (planVariants: PlanVariant[]): Record<BillingOccurence, PlanVariant[]> => planVariants.reduce((plansByBillingOccurence, plan) => {
	try {
		const billingOccurence = getBillingOccurence(plan);

		if (plansByBillingOccurence[billingOccurence]) {
			return {
				...plansByBillingOccurence,
				[billingOccurence]: [...plansByBillingOccurence[billingOccurence], plan],
			};
		}

		return {
			...plansByBillingOccurence,
			[billingOccurence]: [plan],
		};
	} catch (error) {
		return plansByBillingOccurence;
	}
}, {} as Record<BillingOccurence, PlanVariant[]>);

export const getComboComparisonByBillingOccurence = (planVariants: PlanVariant[]): Record<BillingOccurence, PriceComparison> => {
	const plansByBillingOccurence = groupPlansByBillingOccurence(planVariants);

	return Object.entries(plansByBillingOccurence)
		.reduce((comboComparisonByBillingOccurence, [billingOccurence, billingOccurencePlans]) => ({
			...comboComparisonByBillingOccurence,
			[billingOccurence]: getMaxComboComparison(billingOccurencePlans),
		}), {} as Record<BillingOccurence, PriceComparison>);
};

export const hasLanguageSupport = (planVariant: PlanVariant, language: string): boolean => {
	if (!isFeatureFlagEnabled(FeatureFlag.COUNTRY_PORTALS)) {
		return true;
	}

	if (!planVariant.CustomFields.availableLanguages) {
		Sentry.captureMessage('PlanVariant has no "availableLanguages" set', {
			extra: {
				planVariantId: planVariant.Id,
				requestedLanguage: language,
			},
		});

		return false;
	}

	return new RegExp(`\\b(${language})\\b`, 'i').test(planVariant.CustomFields.availableLanguages);
};

export const getPlanVariantsForLanguage = (planVariants: PlanVariant[], language: string): PlanVariant[] => planVariants
	.filter((planVariant) => hasLanguageSupport(planVariant, language));

export const getLocalizedDisplayName = (planVariant: PlanVariant, locale: string): string => {
	if (planVariant.Description && planVariant.Description[locale]) {
		return planVariant.Description[locale];
	}

	// Fallback display name given from billwerk / content api
	if (planVariant.Description && planVariant.Description._c) {
		return planVariant.Description._c;
	}

	if (planVariant.InternalName) {
		return planVariant.InternalName;
	}

	Sentry.captureMessage('PlanVariant has no valid display name', {
		extra: {
			planVariantId: planVariant.Id,
			requestedLanguage: locale,
		},
	});

	return planVariant.Id;
};
