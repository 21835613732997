<template>
	<div
		key="boxPayment"
		class="relative rounded-[14px] shadow-md box-border transition duration-375 ease-smooth text-sm bg-white z-10 p-0"
	>
		<div
			v-if="computedPlanVariant"
			class="py-4 px-6 bg-gray-200 rounded-t-[14px] text-center phablet:flex phablet:items-center phablet:justify-between phablet:p-8 phablet:text-left"
		>
			<p
				class="text-lg font-bold"
				data-cy="selected-planvariant-name"
			>
				{{ getDisplayName(computedPlanVariant) }}
			</p>
			<a
				class="underline text-gray-700 text-xs hover:cursor-pointer hover:no-underline"
				@click="changePlan"
			>
				{{ $t('subscribe.step.plan.boxClose') }}
			</a>
		</div>
		<div class="p-6 phablet:p-8">
			<template v-if="availablePaymentMethods.length > 0">
				<div class="leading-[1.75] mb-8">
					<p class="font-bold">
						{{ $t('subscribe.step.plan.method') }}
					</p>
				</div>
				<div class="leading-[1.75]">
					<div class="grid desktop:grid-cols-2 grid-cols-1 gap-3 mb-4">
						<div>
							<div class="flex flex-wrap mobile:flex-nowrap mb-2 desktop:mb-0 space-x-4">
								<AppChip
									v-for="paymentMethod in availablePaymentMethods"
									:key="paymentMethod.ID"
									v-tippy="{
										content: localize(paymentMethod.name),
										placement: 'bottom',
									}"
									:is-selected="isPaymentMethodSelected(paymentMethod)"
									:is-disabled="waitingForPayment"
									:data-cy="`card_paymentMethod_${paymentMethod.ID}`"
									@click="selectPaymentMethod(paymentMethod)"
								>
									<img
										v-if="paymentMethod.image"
										:src="paymentMethod.image"
										:alt="paymentMethod.image"
										class="h-full object-contain"
									>
									<span v-else>{{ paymentMethod.name }}</span>
								</AppChip>
							</div>
						</div>
						<div>
							<InlineNotification
								class="ml-auto"
								icon="lock-keyhole"
								color="green-light"
								:description="$t('sslBoxDesc')"
							/>
						</div>
					</div>
				</div>
			</template>
			<div
				v-else
				class="leading-[1.75] mb-4"
			>
				<p class="text-xl mb-4">
					{{ $t('subscribe.step.payment.noMethodAvailable') }}
				</p>
			</div>
			<div class="leading-[1.75]">
				<div
					v-if="selectedPaymentMethod"
					key="boxSection"
					class="leading-[1.75] mb-8"
				>
					<TransitionExpand mode="out-in">
						<keep-alive>
							<component
								:is="selectedPaymentMethod.view"
								ref="formRef"
								@change="onChange"
								@submit="onSubmit"
								@success="onSuccess"
								@error="onError"
								@update:secret-payment-data="onUpdateSecretPaymentData"
								@update:customer-change="onUpdateCustomerChange"
							/>
						</keep-alive>
					</TransitionExpand>
				</div>
				<div
					v-if="computedPlanVariant"
					key="boxFooter"
					class="flex justify-between pt-4 border-t border-solid border-gray-600"
				>
					<div class="footer-left">
						<i18n-t
							keypath="price.totalWithBillingOccurence"
							scope="global"
						>
							<template #billingOccurence>
								{{ selectedPlanBillingOccurenceName }}
							</template>
						</i18n-t>
					</div>
					<div class="footer-right">
						<p class="font-bold">
							<span
								v-if="priceBeforeDiscount && currency"
								class="font-normal line-through mr-2"
							>
								{{ getFormattedPrice(priceBeforeDiscount, currency, { fromCents: true }) }}
							</span>
							<span v-if="isTrialOrder && currency">{{ getFormattedPrice(0, currency, { fromCents: true }) }}</span>
							<span v-else-if="pricePerOccurenceInCents && currency">{{ getFormattedPrice(pricePerOccurenceInCents, currency, { fromCents: true }) }}</span>
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { ISOCurrency, getFormattedPrice } from '@/models/Currency';
import {
	PlanVariant, getBillingOccurenceDescription, getLocalizedDisplayName,
} from '@/models/PlanVariant';
import { usePlanVariant } from '@/composables/usePlanVariant';
import { useLanguage } from '@/composables/useLanguage';
import { useCartStore } from '@/store/cart';
import { PaymentMethodSignup } from '@/utilities/PaymentMethods';
import { storeToRefs } from 'pinia';
import { useBillwerkStore } from '@/store/billwerk';
import { CustomerChange, SecretPaymentData } from '@/models/Billwerk';
import { useProductsStore } from '@/store/products';
import { AppChip, InlineNotification } from '@sunlabde/m2m-library';
import { useLocalization } from '@/models/Localization';
import { useI18n } from 'vue-i18n';
import TransitionExpand from './TransitionExpand.vue';

const emit = defineEmits(['selectPaymentMethod', 'change', 'submit', 'success', 'error', 'changePlan']);

const { selectedLanguage } = useLanguage();

const formRef = ref<HTMLFormElement>();
const cartStore = useCartStore();

const productsStore = useProductsStore();
const { allowsTrialOrder } = storeToRefs(productsStore);

const computedPlanVariant = computed(() => cartStore.cart?.planVariant ?? null);

const getDisplayName = (planVariant: PlanVariant) => getLocalizedDisplayName(planVariant, selectedLanguage.value.code);

const hasDiscountApplied = computed(() => cartStore.hasDiscountApplied);
const appliedDiscount = computed(() => cartStore.appliedDiscount);

const pricePerOccurenceInCents = computed<number>(() => (computedPlanVariant.value ? usePlanVariant(computedPlanVariant.value, appliedDiscount.value).pricePerOccurenceInCents.value : 0));

const isTrialOrder = computed(() => allowsTrialOrder.value);

const waitingForPayment = ref(false);

const billwerkStore = useBillwerkStore();
const { availablePaymentMethods, selectedPaymentMethod } = storeToRefs(billwerkStore);

const isPaymentMethodSelected = (paymentMethod: PaymentMethodSignup) => {
	if (selectedPaymentMethod.value) {
		return paymentMethod.ID === selectedPaymentMethod.value.ID;
	}

	return false;
};

const { localize, localizePlural } = useLocalization();
const { t } = useI18n();

const selectedPlanBillingOccurenceName = computed(() => {
	if (isTrialOrder.value) {
		return t('billingOccurence.trial.name');
	}

	if (computedPlanVariant.value && Object.keys(computedPlanVariant.value).length > 0) {
		return localizePlural(getBillingOccurenceDescription(computedPlanVariant.value).name, 1);
	}

	return '';
});

const priceBeforeDiscount = computed(() => {
	if (hasDiscountApplied.value) {
		return pricePerOccurenceInCents.value;
	}

	return 0;
});

const currency = computed<ISOCurrency | null>(() => (computedPlanVariant.value ? usePlanVariant(computedPlanVariant.value, appliedDiscount.value).currency.value : null));

const selectPaymentMethod = (paymentMethod: PaymentMethodSignup) => {
	if (!waitingForPayment.value) {
		billwerkStore.paymentMethod = paymentMethod.ID;
		emit('selectPaymentMethod');
	}
};

const created = async () => {
	if (!selectedPaymentMethod.value && availablePaymentMethods.value.length > 0) {
		selectPaymentMethod(availablePaymentMethods.value[0]);
	}
};

const changePlan = () => emit('changePlan');

const submit = () => {
	waitingForPayment.value = true;
	formRef.value?.submit();
};

const onChange = (isValid: Boolean) => {
	emit('change', isValid);
};

const onSubmit = () => {
	waitingForPayment.value = true;
	formRef.value?.submit();
	emit('submit');
};

const onSuccess = () => {
	waitingForPayment.value = false;
	emit('success');
};

const onError = (message: string) => {
	waitingForPayment.value = false;
	emit('error', message);
};

onMounted(() => {
	created();
});

const onUpdateSecretPaymentData = (secretPaymentData: SecretPaymentData) => {
	billwerkStore.secretPaymentData = secretPaymentData;
};

const onUpdateCustomerChange = (customerChange: CustomerChange) => {
	billwerkStore.customerChange = customerChange;
};

defineExpose({ submit });
</script>
