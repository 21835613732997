import { SupportedLocale, TranslationString } from '@/models/Language';
import i18n from '@/plugins/i18n';
import PianoImage from '@/assets/piano-thumbnail.png';
import GuitarImage from '@/assets/guitar-thumbnail.png';
import DrumsImage from '@/assets/drums-thumbnail.png';
import { PlanCategory } from '@/models/PlanCategory';

export type Instrument = 'piano' | 'guitar' | 'drums';

export type InstrumentDescription<T extends Instrument = Instrument> = {
	id: T,
	name: string,
	displayName: TranslationString,
	image: string,
	planCategory: PlanCategory;
	language: Readonly<SupportedLocale[]>,
}

export type InstrumentDictionary = { [K in Instrument]: InstrumentDescription<K> };

export const instruments: InstrumentDictionary = {
	piano: {
		id: 'piano',
		name: 'Piano',
		displayName: () => i18n.global.t('instrument.piano'),
		image: PianoImage,
		planCategory: 'piano',
		language: ['de', 'en'],
	},
	guitar: {
		id: 'guitar',
		name: 'Guitar',
		displayName: () => i18n.global.t('instrument.guitar'),
		image: GuitarImage,
		planCategory: 'guitar',
		language: ['de', 'en', 'es'],
	},
	drums: {
		id: 'drums',
		name: 'Drums',
		displayName: () => i18n.global.t('instrument.drums'),
		image: DrumsImage,
		planCategory: 'drums',
		language: ['de'],
	},
} as const;

export const getInstrumentsForLanguage = (language: SupportedLocale): InstrumentDescription[] => Object.values(instruments).filter((instrument) => instrument.language.includes(language));

export const getInstrumentsForPlanCategory = (planCategory: PlanCategory): Instrument[] => {
	if (['plus', 'combo'].includes(planCategory)) {
		return Object.keys(instruments) as Instrument[];
	}

	const instrument = Object.values(instruments).find((i) => i.planCategory === planCategory);

	return instrument ? [instrument.id] : [];
};
