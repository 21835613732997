<template>
	<div>
		<h2 class="text-center text-[24px] mb-8 mx-auto tablet:w-8/12 desktop:w-10/12 font-bold">
			<span class="text-secondary-500">{{ $t('subscribe.step.course.header.1', [authUser?.firstname]) }}</span>{{ $t('subscribe.step.course.header.2') }}
		</h2>
		<div class="w-full mb-8">
			<div class="flex justify-center items-center flex-wrap phablet:flex-nowrap gap-4">
				<TabBar
					v-model="selectedBillingOccurence"
					class="w-[80%] mb-12"
					:items="availableBillingOccuranceOptions"
				/>
			</div>
			<div class="flex justify-center flex-wrap tablet:flex-nowrap items-stretch gap-y-12 gap-x-6 mb-8">
				<CardPlanType
					v-for="card in planTypeCards"
					:key="card.type"
					:content="card"
					class="mobile:w-[80%] phablet:w-[65%] tablet:w-auto"
					:class="{ 'tablet:flex-1': planTypeCards.length > 1 }"
					:is-selected="card.type === selectedPlanType"
					:is-highlighted="!!selectedPlanType"
					@click="selectedPlanType = card.type"
				/>
			</div>
		</div>
		<TransitionGroup name="fade">
			<div
				key="stepSection"
				class="w-full mb-8"
			>
				<div v-if="selectedPlanType && availablePlanCategories.length > 1" class="mb-8">
					<h3 class="font-bold text-center mb-8">
						{{ $t('subscribe.step.course.instrument') }}
					</h3>
					<div class="flex justify-center flex-wrap mobile:flex-nowrap gap-4">
						<AppChip
							v-for="instrument in instruments"
							:key="instrument.id"
							:is-selected="isCategorySelected(instrument.planCategory)"
							:is-highlighted="isHighlighted"
							@click="selectPlanCategory(instrument.planCategory)"
						>
							{{ localize(instrument.displayName) }}
						</AppChip>
					</div>
				</div>
				<div v-if="selectedPlanCategory && availablePlanCategories.length > 1 && allowsTrialOrder">
					<h3 class="font-bold text-center mb-8">
						{{ $t('subscribe.step.course.experienceLevel.title') }}
					</h3>
					<div class="flex justify-center flex-wrap mobile:flex-nowrap gap-4">
						<AppChip
							v-for="experienceLevel in experienceLevels"
							:key="experienceLevel.id"
							:is-selected="experienceLevel.id === selectedExperienceLevel.id"
							:data-cy="`select_experienceLevel_${experienceLevel.id}`"
							@click="selectExperienceLevel(experienceLevel.id)"
						>
							{{ localize(experienceLevel.name) }}
						</AppChip>
					</div>
				</div>
			</div>
		</TransitionGroup>
		<div
			key="stepFooter"
			class="mt-8 text-center"
		>
			<AppButton
				:is-disabled="!validateStepSelection"
				:is-loading="loading"
				data-cy="button_nextStep"
				class="mx-auto"
				@click="nextStep"
			>
				{{ $t('button.nextStep') }}
			</AppButton>
			<div class="mt-6">
				<div class="flex flex-col justify-center items-center phablet:flex-row divide-y phablet:divide-y-0 phablet:divide-x divide-solid divide-gray-300">
					<div class="py-1 px-4 phablet:text-right">
						<AppLink
							:link="{ name: 'redeem-coupon' }"
							class="text-gray-700 hover:text-primary-400"
						>
							{{ $t('button.coupon.redeem') }}
						</AppLink>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { ExperienceLevel, experienceLevels } from '@/models/ExperienceLevel';
import { SupportedLocale, localize } from '@/models/Language';
import { computed, ref, watch } from 'vue';
import { PlanCategory } from '@/models/PlanCategory';
import { useCartStore } from '@/store/cart';
import { getInstrumentsForLanguage } from '@/utilities/Instrument';
import { useUserStore } from '@/store/user';
import { storeToRefs } from 'pinia';
import { useProductsStore } from '@/store/products';
import { useLanguage } from '@/composables/useLanguage';
import { useMainStore } from '@/store/main';
import { useI18n } from 'vue-i18n';
import {
	AppChip, AppLink, TabBar, CardPlanType,
} from '@sunlabde/m2m-library';
import { Product } from '@/models/Product';
import { usePlanTypeCards } from '@/composables/usePlanTypeCards';
import { useAuthStore } from '@/store/auth';
import { SelectOption } from '@/models/Select';
import { billingOccurences } from '@/models/BillingOccurence';

const { t } = useI18n();

const emits = defineEmits(['signupTrackingEvent', 'continue']);
const loading = ref(false);
const { selectedLanguage, selectedLanguageCode } = useLanguage();

const mainStore = useMainStore();
const { selectedPlanCategory, endOfTrialDate } = storeToRefs(mainStore);

const userStore = useUserStore();
const { selectedExperienceLevel } = storeToRefs(userStore);

const authStore = useAuthStore();
const authUser = computed(() => authStore.user);

const productsStore = useProductsStore();
const {
	allowsTrialOrder,
	availableBillingOccurences,
	productsForBillingOccurence,
	selectedBillingOccurence,
	selectedPlanType,
} = storeToRefs(productsStore);

const availableBillingOccuranceOptions = computed<SelectOption[]>(() => availableBillingOccurences.value
	.map((occurrence) => ({
		label: t('billingOccurence.term.inMonth', billingOccurences[occurrence].months),
		value: occurrence,
	})));

const validateStepSelection = computed(() => selectedPlanCategory.value && (!!selectedExperienceLevel.value || !allowsTrialOrder.value));

const selectExperienceLevel = (experienceLevel: ExperienceLevel) => {
	userStore.experienceLevel = experienceLevel;
	emits('signupTrackingEvent', 'selectExperienceLevel');
};

const selectPlanCategory = (planCategory: PlanCategory | null) => {
	mainStore.planCategory = planCategory;

	emits('signupTrackingEvent', 'selectPlanCategory');

	if (!selectedExperienceLevel.value && allowsTrialOrder.value) {
		userStore.experienceLevel = 'beginner';
	}
};

const availablePlanCategories = computed(() => [...new Set(productsForBillingOccurence.value
	.filter((product) => product.planVariant.CustomFields.planType === selectedPlanType.value)
	.map((product) => product.planVariant.CustomFields.category))]);

watch(availablePlanCategories, (categories) => {
	if (categories.length === 1) {
		selectPlanCategory(categories[0]);
	} else {
		selectPlanCategory(null);
	}
});

const instruments = computed(() => getInstrumentsForLanguage(selectedLanguage.value.code as SupportedLocale)
	.filter((instrument) => availablePlanCategories.value.some((category) => category === instrument.planCategory)));

const cartStore = useCartStore();

const selectProduct = (product: Product) => {
	const discountStartDate = allowsTrialOrder.value ? endOfTrialDate.value : undefined;
	cartStore.setCartFromProduct(product, discountStartDate);
	emits('signupTrackingEvent', 'selectPlan');
};

const selectedProduct = computed(() => productsForBillingOccurence.value.find((product) => product.planVariant.CustomFields.category === selectedPlanCategory.value));

const nextStep = () => {
	cartStore.clearCart();

	if (!selectedProduct.value) return;

	selectProduct(selectedProduct.value);
	loading.value = true;
	emits('continue');
};

const isCategorySelected = (category: PlanCategory) => mainStore.planCategory === category;
const isHighlighted = computed(() => mainStore.planCategory !== null);

const { planTypeCards } = usePlanTypeCards({
	products: productsForBillingOccurence,
	isTrial: allowsTrialOrder,
	languageCode: selectedLanguageCode,
});
</script>
